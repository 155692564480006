/**
 * @name Text
 * @description
 */
//============================================================================ 기본폰트스타일
.essential-text {
  vertical-align: middle;
  font-family: 'Pretendard';
}
//============================================================================ input,textarea
textarea {
  resize: none; // 리사이즈제거
}
//============================================================================ Text
body {
  .Display_LB {
    font-size: 140px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 294px */
    letter-spacing: -0.4px;
  }
  .Display_MB {
    font-size: 126px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 210px */
    letter-spacing: -0.4px;
  }
  .Display_SB {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 120px */
    letter-spacing: -0.4px;
  }
  //
  .Headline_LB {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: -0.4px;
  }
  .Headline_MB {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 84px */
    letter-spacing: -0.4px;
  }
  .Headline_SB {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 66px */
    letter-spacing: -0.4px;
  }
  //================================================ Caption

  // Body
  .Title_LB {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 66px */
    letter-spacing: -0.4px;
  }
  .Title_LSB {
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 66px */
    letter-spacing: -0.4px;
  }
  .Title_LR {
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 66px */
    letter-spacing: -0.4px;
  }
  //================================================ Body
  .Body_LLB {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 294px */
    letter-spacing: -0.4px;
  }
  .Body_LLSB {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 294px */
    letter-spacing: -0.4px;
  }
  .Body_LLR {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 54px */
    letter-spacing: -0.4px;
  }
  .Body_LB {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 54px */
    letter-spacing: -0.4px;
  }
  .Body_LSM {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 54px */
    letter-spacing: -0.4px;
  }
  .Body_LR {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 54px */
    letter-spacing: -0.4px;
  }
  .Body_LSB {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 54px */
    letter-spacing: -0.4px;
  }
  .Body_MB {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 36px */
    letter-spacing: -0.4px;
  }
  .Body_MSB {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 36px */
    letter-spacing: -0.4px;
  }
  .Body_MR {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 36px */
    letter-spacing: -0.4px;
  }

  .Body_SB {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 36px */
    letter-spacing: -0.4px;
  }
  .Body_SSM {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 36px */
    letter-spacing: -0.4px;
  }
  .Body_SR {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 36px */
    letter-spacing: -0.4px;
  }

  .Body_SSB {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    letter-spacing: -0.4px;
  }
  .Body_MB {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 45px */
    letter-spacing: -0.4px;
  }
  .Body_MSB {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 45px */
    letter-spacing: -0.4px;
  }
  .Body_MR {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 45px */
    letter-spacing: -0.4px;
  }
  //================================================ Button

  .Button_LB {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 66px */
    letter-spacing: -0.4px;
  }
  .Button_MB {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 60px */
    letter-spacing: -0.4px;
  }
  .Button_SB {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 54px */
    letter-spacing: -0.4px;
  }
  //================================================ Caption
  .Caption_LR {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 36px */
    letter-spacing: -0.4px;
  }
  .Caption_MR {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 30px */
    letter-spacing: -0.4px;
  }
}
